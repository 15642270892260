import TeaserMap from '~@Molecules/teaser-map/teaser-map'

import { IMapList } from '~@Types/components/IMap'

export const MapList = ({ list }: IMapList) => {
  return (
    <ul className="MapList">
      {list.map(
        (poi, index) =>
          poi && (
            <li
              key={index}
              className={`MapList-item border-t-sm border-grey-light py-lg desktop:py-3xl desktop:px-xl ${index === list.length + 1 ? 'border-b-sm' : ''}`}
            >
              <TeaserMap {...poi} />
            </li>
          )
      )}
    </ul>
  )
}

export default MapList
