'use client'

import { useEffect, useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import { ControlledMap } from './controlled-map'

import 'leaflet/dist/leaflet.css'
import './leaflet-map-global.css'

import MapFilterList from '~@Molecules/map-filter-list/map-filter-list'
import MapList from '~@Organisms/map-list/map-list'

import {
  IGeoJsonItem,
  ILeafletMapProps,
  IPoiItem,
} from '~@Types/components/ILeafletMap'
import { ITeaserMap } from '~@Types/components/IMap'

const MapContainer = dynamic(
  () => import('react-leaflet').then((mod) => mod.MapContainer),
  { ssr: false }
)

/**
 * @component LeafletMap
 * @description A comprehensive map component that displays and manages geographical data with filtering capabilities
 *
 * @typedef {Object} ILeafletMapProps
 * @property {IGeoJsonItem[]} geoJsonItems - Array of geographical areas to display on the map
 * @property {IPoiItem[]} poiArray - Array of Points of Interest to display
 * @property {string} buttonPoiLabel - Label for POI buttons
 * @property {string} subsidiaryLabel - Label for subsidiary filters
 * @property {string} commercialActivityLabel - Label for commercial activity filters
 * @property {string} itemAllLabel - Label for "show all" option
 * @property {string} itemOtherLabel - Label for "other" category
 * @property {string} itemOtherId - Identifier for "other" category
 */
export const LeafletMap: React.FC<ILeafletMapProps> = ({
  geoJsonItems = [],
  poiArray = [],
  buttonPoiLabel = '',
  subsidiaryLabel = '',
  commercialActivityLabel = '',
  itemAllLabel = '',
  itemOtherLabel = '',
  itemOtherId = 'urban_mine',
}) => {
  /**
   * @state selectedGroupArea
   * @description Currently selected geographical area group
   */
  const [selectedGroupArea, setSelectedGroupArea] =
    useState<IGeoJsonItem | null>(null)

  /**
   * @state selectedItem
   * @description Currently selected item (can be a geographical area or urban mine)
   */
  const [selectedItem, setSelecteditem] = useState<
    IGeoJsonItem | 'urban_mine' | null
  >(null)

  /**
   * @state activePoi
   * @description Array of currently active Points of Interest
   */
  const [activePoi, setActivePoi] = useState<IPoiItem[]>(poiArray ?? [])

  /**
   * @memo urbanMineArea
   * @description Calculates and memoizes the urban mine areas based on POI data
   * @returns {Object} Formatted GeoJSON object representing urban mine areas
   */
  const urbanMineArea = useMemo(() => {
    const urbanMinePois =
      poiArray?.filter((poi) => Boolean(poi?.urban_mine)) ?? []

    const features = urbanMinePois.map((poi) => {
      const lat = Number(poi?.lat) || 0
      const lng = Number(poi?.lng) || 0
      const offset = 0.5 // Offset for creating polygon around point

      return {
        type: 'Feature',
        properties: {
          name: poi?.name ?? 'Unknown',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [lng - offset, lat - offset],
              [lng + offset, lat - offset],
              [lng + offset, lat + offset],
              [lng - offset, lat + offset],
              [lng - offset, lat - offset],
            ],
          ],
        },
      }
    })

    return {
      label: 'Urban mine',
      geojson: {
        type: 'FeatureCollection',
        features,
      },
    }
  }, [poiArray])

  /**
   * @memo computedActivePoi
   * @description Calculates active POIs based on current selection
   * @returns {IPoiItem[]} Array of POIs with active status
   */
  const computedActivePoi = useMemo(() => {
    if (!poiArray) return []

    if (selectedItem === itemOtherId) {
      return poiArray.map((poi) => ({
        ...poi,
        active: Boolean(poi?.urban_mine),
      }))
    }

    return poiArray.map((poi) => ({
      ...poi,
      active:
        selectedItem && typeof selectedItem !== 'string'
          ? poi?.groupArea === selectedItem?.name
          : true,
    }))
  }, [selectedItem, poiArray, itemOtherId])

  /**
   * @effect
   * @description Updates active POIs when computed values change
   */
  useEffect(() => {
    setActivePoi(computedActivePoi)
  }, [computedActivePoi])

  /**
   * @constant mapContainerProps
   * @description Default properties for the map container
   */
  const mapContainerProps = {
    center: [51.505, -0.09], // Default center coordinates (London)
    zoom: 2, // Default zoom level
  }

  /**
   * @memo activePoiOrdered
   * @description Processes and sorts active POIs for display
   * @returns {ITeaserMap[]} Sorted and filtered array of POIs
   */
  const activePoiOrdered = useMemo(() => {
    return (
      [...activePoi]
        // Filter out incomplete POIs
        .filter(
          (poi) =>
            poi.name !== '' &&
            poi.adress !== '' &&
            poi.phone !== '' &&
            poi.groupArea !== ''
        )
        // Sort by name
        .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
        // Sort by type priority
        .sort((a, b) => {
          const typeOrder = {
            'Siège social': 1,
            Headoffice: 1,
            Filiale: 2,
            Subsidiary: 2,
            'Autres pays': 3,
            'Other country': 3,
          }
          return (
            (typeOrder[a.type as keyof typeof typeOrder] ?? 4) -
            (typeOrder[b.type as keyof typeof typeOrder] ?? 4)
          )
        })
        // Filter active POIs
        .filter((poi) => poi.active)
        // Transform to teaser format
        .map(
          (poi): ITeaserMap => ({
            name: poi.name ?? '',
            adress: poi.adress ?? '',
            phone: poi.phone ?? '',
            country: poi.country ?? '',
            mail: poi.mail ?? '',
            website: poi.website ?? '',
            link: poi.link,
          })
        )
    )
  }, [activePoi, buttonPoiLabel])

  /**
   * @function handleSelectedArea
   * @description Handles area selection and updates related states
   * @param {IGeoJsonItem | 'urban_mine' | null} item - Selected area item
   */
  const handleSelectedArea = (item: IGeoJsonItem | 'urban_mine' | null) => {
    setSelecteditem(item)
    if (item === 'urban_mine') {
      setSelectedGroupArea(urbanMineArea)
    } else {
      setSelectedGroupArea(item)
    }
  }

  return (
    <div>
      <div className="container px-lg tablet:px-3xl">
        <MapFilterList
          itemAll={itemAllLabel ?? ''}
          itemOther={itemOtherLabel ?? ''}
          itemOtherId={itemOtherId ?? 'urban_mine'}
          geoJsonItems={geoJsonItems ?? []}
          selectedItem={selectedItem}
          setSelectedItem={handleSelectedArea}
          subsidiaryLabel={subsidiaryLabel ?? ''}
          commercialActivityLabel={commercialActivityLabel ?? ''}
        />
      </div>
      <MapContainer
        {...mapContainerProps}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 'var(--xl, 32px)',
          alignSelf: 'stretch',
          height: '563px',
        }}
      >
        <ControlledMap
          geoJsonItems={geoJsonItems}
          selectedGroupArea={selectedGroupArea}
          poiArray={activePoi}
        />
      </MapContainer>
      <div className="container p-xl desktop:py-5xl desktop:px-9xl">
        <MapList list={activePoiOrdered} />
      </div>
    </div>
  )
}
