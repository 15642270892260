'use client'

import React from 'react'

// Custom components imports
import SecureEmailLink from '~@Atoms/secure-email-link/secure-email-link'
import LinkItem from '~@Atoms/link-item/link-item'

// Types imports
import { IPoiItem } from '~@Types/components/ILeafletMap'

// Icons imports
import { Envelope, Phone, Link } from '@phosphor-icons/react'

import dynamic from 'next/dynamic'

const DynamicPopup = dynamic(
  () => import('react-leaflet').then((mod) => mod.Popup),
  {
    ssr: false,
  }
)

/**
 * MapPopin Component
 * Displays a popup with POI (Point of Interest) information on a Leaflet map
 * @param {IPoiItem} poi - Point of Interest data
 */
export const MapPopin: React.FC<IPoiItem> = (poi: IPoiItem) => {
  const commonClassNameWithIcon = 'flex items-center gap-x-sm mb-2xs'

  const phoneLabel = poi?.phone
    ? poi.phone.replace(
        /(\+\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
        '$1 $2 $3 $4 $5 $6'
      )
    : ''
  return (
    <DynamicPopup>
      <div className="MapPopin flex flex-col p-md gap-xs typeface-subtitle2-standard text-brand-primary">
        {/* POI Name */}
        {poi?.name && (
          <p className="MapPopin-name typeface-subtitle2-special !m-none">
            {poi.name}
          </p>
        )}

        {/* POI Address */}
        {poi?.adress && (
          <p className="MapPopin-address !m-none">{poi.adress}</p>
        )}

        {/* POI Phone Number */}
        {poi?.phone && (
          <div className={commonClassNameWithIcon}>
            <Phone />
            <a
              className="MapPopin-phone-link"
              href={`tel:${poi.phone}`}
              rel="noopener noreferrer"
            >
              {phoneLabel}
            </a>
          </div>
        )}

        {/* POI Email */}
        {poi?.mail && (
          <div className={commonClassNameWithIcon}>
            <Envelope />
            <SecureEmailLink className="MapPopin-mail-link" email={poi.mail} />
          </div>
        )}

        {/* POI Website */}
        {poi?.website && (
          <div className={commonClassNameWithIcon}>
            <Link size={20} />
            <LinkItem
              className="MapPopin-link-link"
              tagElt="a"
              href={poi.website}
              target="_blank"
            >
              {poi.website}
            </LinkItem>
          </div>
        )}
      </div>
    </DynamicPopup>
  )
}
