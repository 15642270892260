import { MapPin, Record } from '@phosphor-icons/react/dist/ssr'
import ButtonFilter from '~@Atoms/button-filter/button-filter'

import { IGroupAreaSelectorProps } from '~@Types/components/ILeafletMap'

const MapFilterList = ({
  itemAll,
  itemOther,
  geoJsonItems,
  selectedItem,
  setSelectedItem,
  subsidiaryLabel,
  commercialActivityLabel,
  itemOtherId,
}: IGroupAreaSelectorProps) => {
  return (
    <div className="MapFilterList desktop:flex desktop:gap-md desktop:pb-xl desktop:pt-3xl">
      <ul className="MapFilterList-list flex flex-wrap items-center gap-xs pt-md pb-lg desktop:py-none">
        <li>
          <ButtonFilter
            label={itemAll}
            active={selectedItem === null}
            onClick={() => setSelectedItem(null)}
          />
        </li>
        {geoJsonItems.map((item, index) => (
          <li key={index}>
            <ButtonFilter
              key={index}
              label={item.name ?? ''}
              active={selectedItem === item}
              onClick={() => setSelectedItem(item)}
            />
          </li>
        ))}
        <li>
          <ButtonFilter
            label={itemOther}
            active={selectedItem === itemOtherId}
            onClick={() => setSelectedItem(itemOtherId)}
          />
        </li>
      </ul>
      <ul className="MapFilterList-legends py-md flex items-center gap-md pl-sm typeface-subtitle2-special border-t-xs border-t-grey-semi desktop:border-t-[0px] desktop:border-l-xs desktop:border-l-grey-semi desktop:py-none">
        <li>
          <div className="MapFilterList-legend flex items-start gap-2xs text-brand-primary typeface-subtitle1-standard">
            <MapPin
              className="text-[#E92184]"
              width={18}
              height={18}
              weight="fill"
            />
            {subsidiaryLabel}
          </div>
        </li>
        <li>
          <div className="MapFilterList-legend flex items-start gap-2xs text-brand-primary typeface-subtitle2-standard">
            <Record className="text-[#E92184]" width={18} height={18} />
            {commercialActivityLabel}
          </div>
        </li>
      </ul>
    </div>
  )
}

export default MapFilterList
