import { Phone, MapPin, Envelope, Link } from '@phosphor-icons/react/dist/ssr'

import SecureEmailLink from '~@Atoms/secure-email-link/secure-email-link'
import LinkItem from '~@Atoms/link-item/link-item'
import ButtonSecondary from '~@Atoms/button-secondary/button-secondary'

import { ITeaserMap } from '~@Types/components/IMap'

export const TeaserMap = ({
  name,
  adress,
  phone,
  mail,
  website,
  country,
  link,
}: ITeaserMap) => {
  const commonClassNameWithIcon = 'flex items-start gap-x-sm mb-2xs'

  return (
    <div className="TeaserMap w-full typeface-subtitle2-standard text-grey-dark desktop:flex desktop:items-start desktop:gap-x-md">
      <p className="TeaserMap-title typeface-subtitle1-special font-bold text-black-black mb-md desktop:basis-2/12 desktop:px-md">
        {name}
      </p>
      <p className="TeaserMap-country mb-md desktop:basis-2/12 desktop:px-md">
        {country}
      </p>
      <div className="TeaserMap-address desktop:flex-none desktop:basis-4/12 desktop:px-md">
        {adress && (
          <div className={commonClassNameWithIcon}>
            <MapPin className="flex-none basis-[20px]" size={20} />
            {adress}
          </div>
        )}
        {mail && (
          <div className={commonClassNameWithIcon}>
            <Envelope className="flex-none basis-[20px]" size={20} />
            <SecureEmailLink
              className="MapPopin-mail-link text-left"
              email={mail}
            />
          </div>
        )}
        {phone && (
          <div className={commonClassNameWithIcon}>
            <Phone className="flex-none basis-[20px]" size={20} />
            <LinkItem tagElt="a" href={`tel:${phone}`}>
              {phone}
            </LinkItem>
          </div>
        )}
        {website && (
          <div className={commonClassNameWithIcon}>
            <Link size={20} />
            <LinkItem
              className="MapPopin-link-link"
              tagElt="a"
              href={website}
              target="_blank"
            >
              {website}
            </LinkItem>
          </div>
        )}
      </div>
      {!!link && (
        <div className="TeaserMap-action mt-md desktop:basis-4/12 desktop:mt-none">
          <ButtonSecondary {...link} />
        </div>
      )}
    </div>
  )
}

export default TeaserMap
