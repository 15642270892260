'use client'

// React & Next.js imports
import { useEffect, useRef, useState } from 'react'
import dynamic from 'next/dynamic'

// Third party imports
import 'leaflet/dist/leaflet.css'

// Local imports
import { MapPopin } from './map-popin'
import { MapController } from './map-controller'

// Conditional Leaflet import
let L
if (typeof window !== 'undefined') {
  L = require('leaflet')
}

/**
 * countriesGeoJson is a geoJson file downloaded from: https://github.com/datasets/geo-countries/blob/master/data/countries.geojson
 * and simplified with https://mapshaper.org/
 * */
import countriesGeoJson from './countries.json'
import {
  IGeoJsonItem,
  ILeafletMapProps,
  IPoiItem,
} from '~@Types/components/ILeafletMap'
import { formatError } from '@/helpers/formatError'

/**
 * Returns a default icon in case of errors
 */
const getDefaultIcon = (): import('leaflet').Icon => {
  return new L.Icon({
    iconUrl: '/icons/map/default-pin.svg',
    iconSize: [48, 48],
    iconAnchor: [24, 48],
    popupAnchor: [0, -48],
  })
}

/**
 * ControlledMap Component
 *
 * A controlled map component that displays GeoJSON data and POI markers.
 *
 * @param {Object} props
 * @param {IGeoJsonItem | null} props.selectedGroupArea - Currently selected geographical area
 * @param {IPoiItem[]} props.poiArray - Array of Points of Interest to display on the map
 */
export const ControlledMap: React.FC<
  ILeafletMapProps & {
    selectedGroupArea: IGeoJsonItem | null
  }
> = ({ selectedGroupArea, poiArray = [] }) => {
  // Default style for GeoJSON features
  const defaultStyle = {
    color: '#e1e1e1',
    weight: 2,
    opacity: 1,
    fillColor: '#fff',
    fillOpacity: 0.5,
  }

  // Function to handle interactions with each GeoJSON feature
  const onEachFeature = (feature, layer) => {
    layer.setStyle(defaultStyle)
    layer.on({
      mouseover: () => {
        //layer.setStyle(highlightStyle)
      },
      mouseout: () => {
        //layer.setStyle(defaultStyle)
      },
      click: () => {
        // map.flyToBounds(layer.getBounds())
      },
    })
  }

  /**
   * Creates a custom icon based on POI type
   * @param {IPoiItem} poi - Point of Interest item
   * @returns {import('leaflet').Icon} Leaflet icon instance
   */
  const customIcon = (poi: IPoiItem): import('leaflet').Icon => {
    // Validate poi parameter
    if (!poi || !poi.type) {
      return getDefaultIcon()
    }

    try {
      switch (poi.type) {
        case 'Subsidiary':
        case 'Headoffice':
        case 'Filiale':
        case 'Siège social':
          return new L.Icon({
            iconUrl: poi.active
              ? '/icons/map/pin.svg'
              : '/icons/map/pin-alternate.svg',
            iconSize: [56, 56],
            iconAnchor: [28, 28],
            popupAnchor: [0, -28],
          })
        default:
          return new L.Icon({
            iconUrl: poi.active
              ? '/icons/map/group.svg'
              : '/icons/map/group-alternate.svg',
            iconSize: [48, 48],
            iconAnchor: [24, 48],
            popupAnchor: [0, -48],
          })
      }
    } catch (error) {
      formatError(error, 'ControlledMap')
      return getDefaultIcon()
    }
  }

  const customMarker = (poi: IPoiItem) => {
    return {
      position: [poi.lat, poi.lng],
      icon: customIcon(poi),
      children: <MapPopin {...poi} />,
    }
  }

  const countriesGeoJsonProps = {
    data: countriesGeoJson,
    onEachFeature: onEachFeature as any,
    renderer: L.svg({ padding: 10 }),
  }

  const markerRefs = useRef<{ [key: number]: import('leaflet').Marker }>({})

  const [GeoJSON, setGeoJSON] = useState<
    typeof import('react-leaflet').GeoJSON | null
  >(null)

  useEffect(() => {
    import('react-leaflet').then(({ GeoJSON }) => {
      setGeoJSON(GeoJSON)
    })
  }, [])

  useEffect(() => {
    const headquartersPoi = poiArray?.find(
      (poi) => poi.type === 'Headoffice' || poi.type === 'Siège social'
    )

    if (headquartersPoi?.id && markerRefs.current[headquartersPoi.id]) {
      setTimeout(() => {
        if (headquartersPoi.id && markerRefs.current[headquartersPoi.id]) {
          markerRefs.current[headquartersPoi.id].openPopup()
        }
      }, 100)
    }
  }, [])

  const LeafletMarker = dynamic(
    () => import('react-leaflet').then((mod) => mod.Marker),
    {
      ssr: false,
    }
  )

  return (
    <>
      {/* Render GeoJSON data on the map */}
      {GeoJSON && <GeoJSON {...countriesGeoJsonProps} />}

      {/* Render POI markers */}
      {poiArray?.map((poi) => (
        <LeafletMarker
          key={poi.id}
          {...customMarker(poi)}
          ref={(ref) => {
            if (ref && poi.id) {
              markerRefs.current[poi.id] = ref
            }
          }}
        />
      ))}

      {/* Controller for handling selected GeoJSON item */}
      <MapController
        selectedGroupArea={selectedGroupArea}
        activePois={poiArray ?? []}
      />
    </>
  )
}
export default ControlledMap
