'use client'

// React imports
import { useEffect, useRef } from 'react'

// Third party imports
import { useMap } from 'react-leaflet'

// Types
import { IGeoJsonItem, IPoiItem } from '~@Types/components/ILeafletMap'

// Helpers
import { formatError } from '@/helpers/formatError'

/**
 * MapController component handles the map view updates based on selected areas and POIs
 * @param selectedGroupArea - Currently selected group area or 'urban_mine'
 * @param activePois - Array of active Points of Interest
 */
export const MapController: React.FC<{
  selectedGroupArea: IGeoJsonItem | null
  activePois: IPoiItem[]
}> = ({ selectedGroupArea, activePois }) => {
  const map = useMap()
  // Reference to track if an animation is in progress
  const isAnimating = useRef(false)

  useEffect(() => {
    if (isAnimating.current) {
      return
    }

    /**
     * Handler for map movement end event
     * Resets the animation flag when movement is complete
     */
    const handleMoveEnd = () => {
      isAnimating.current = false
    }

    /**
     * Main function to update map view based on current selection
     * Handles three cases:
     * 1. Urban mine view (active POIs)
     * 2. Selected group area view
     * 3. World view (centered on headquarters)
     */
    const updateMapView = () => {
      map.closePopup()
      isAnimating.current = true

      if (selectedGroupArea && selectedGroupArea.geojson) {
        try {
          const leaflet = require('leaflet')
          const bounds = leaflet.geoJSON(selectedGroupArea.geojson).getBounds()
          if (bounds.isValid()) {
            map.fitBounds(bounds, {
              padding: [50, 50],
              maxZoom: 4,
              animate: true,
            })
          }
        } catch (error) {
          formatError(error, 'MapController fitBounds')
        }
      } else {
        const worldCenter = activePois.find(
          (poi) => poi.type === 'Headoffice' || poi.type === 'Siège social'
        )
        if (worldCenter) {
          map.flyTo([worldCenter.lat, worldCenter.lng], 2.5, { animate: true })
        } else {
          map.fitWorld()
        }
      }
    }

    // Add small delay to ensure data is loaded before updating view
    const timeoutId = setTimeout(updateMapView, 100)

    // Listen for map movement end
    map.on('moveend', handleMoveEnd)

    // Cleanup function
    return () => {
      clearTimeout(timeoutId)
      map.off('moveend', handleMoveEnd)
      isAnimating.current = false
    }
  }, [selectedGroupArea, activePois, map])

  return null
}
