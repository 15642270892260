import { IButtonFilter } from '~@Types/components/IButton'

export const ButtonFilter = ({ label, active, onClick }: IButtonFilter) => {
  return (
    <button
      onClick={onClick}
      aria-pressed={active}
      aria-label={label}
      className={`ButtonFilter rounded-full border-xs py-xs px-sm typeface-subtitle2-standard text-brand-primary border-brand-primary transition-colors ease-in-out duration-300 ${active ? 'bg-brand-primary text-white-white' : ''}`}
    >
      {label}
    </button>
  )
}

export default ButtonFilter
