'use client'

import { useState } from 'react'

import { ISecureEmailLink } from '~@Types/components/ISecureEmailLinks'

const SecureEmailLink = ({ className, email }: ISecureEmailLink) => {
  const [isRevealed, setIsRevealed] = useState(false)

  // Fonction pour encoder l'email
  const encodeEmail = (emailAddress) => {
    return emailAddress
      .split('')
      .map((char) => `&#${char.charCodeAt(0)};`)
      .join('')
  }

  // Fonction pour obfusquer l'email
  const obfuscateEmail = (emailAddress) => {
    const [username, domain] = emailAddress.split('@')
    return `${username.slice(0, 3)}...@${domain}`
  }

  const handleClick = (e) => {
    if (!isRevealed) {
      e.preventDefault()
      setIsRevealed(true)
      return
    }

    window.location.href = `mailto:${email}`
  }

  return (
    <button
      onClick={handleClick}
      className={className}
      dangerouslySetInnerHTML={{
        __html: isRevealed ? encodeEmail(email) : obfuscateEmail(email),
      }}
    />
  )
}

export default SecureEmailLink
